import moment from "moment";

export default {
  methods: {
    $formatDateTime(date) {
      return moment(date).format("DD.MM.YYYY - HH:mm");
    },
    $formatForQuery(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
